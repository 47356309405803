import './styles.css'
import {useSentry} from "@/shared/useSentry";
import {createLayout} from "@/widgets/layout";
import {createPreorderForm} from "@/widgets/preorder";
import {createFormCooperation} from "@/widgets/form-cooperation";
import {createCruiseList} from "@/entity/cruise/ui/cruise-list";
import {createFaqAccordion} from "@/entity/cruise/ui/faq-accordion";
import {createGallerySlider} from "@/entity/cruise/ui/gallery-slider";
import {createOrderFlow} from "@/feature/order-flow";
import {setupVkAnalytics} from "@/shared/analytics/vk";
import {useAddToCartGoal} from "@/shared/analytics/useAddToCartGoal";
import {useMarketNotification} from "@/feature/useMarketNotification";
import {initCookieBottomDrawer} from "@/shared/ui/cookie-bottom-drawer";
import {initRefundModal} from "@/widgets/refund";

document.addEventListener('DOMContentLoaded',() => {
    useSentry();
    setupVkAnalytics();
    useAddToCartGoal();

    initCookieBottomDrawer()

    useMarketNotification();
    createCruiseList();
    createFaqAccordion();
    createGallerySlider();
    createLayout();
    initRefundModal();

    //forms
    const preorderForm = createPreorderForm();
    // noinspection JSCheckFunctionSignatures
    createOrderFlow({
        isPassengersRender: () => {
            let result = false;
            if (!preorderForm.isDisabled()) {
                preorderForm.setDisabled();
                result = true;
            }
            return result;
        }
    });
    createFormCooperation();
});
